import React, { useRef } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Form } from 'react-bootstrap';

import SignatureCanvas from 'react-signature-canvas';
import InputMask from 'react-input-mask';
import DaumPostcode from 'react-daum-postcode';
import { useState } from 'react';
import { useEffect } from 'react';

const AddressModalPopup = ({ isAddressModalshow, isTitle, setText, setModalClose }) => {
    const [isAddress, setAddress] = useState('');
    const [isAddressDetail, setAddressDetail] = useState('');
    const [isOpen, setIsOpen] = useState(true);

    const themeObj = {
        bgColor: '#FFFFFF',
        pageBgColor: '#FFFFFF',
        postcodeTextColor: '#C05850',
        emphTextColor: '#222222',
    };

    const postCodeStyle = {
        width: '100%',
    };

    const activeEnter = (e) => {
        if (e.key === 'Enter') {
            setInputUpdate();
        }
    };

    const completeHandler = (data) => {
        const { address, zonecode } = data;
        setAddress(address);
        setIsOpen(false);
    };

    const closeHandler = (state) => {};

    const setResetAddress = () => {
        setAddress('');
        setAddressDetail('');
        setIsOpen(true);
    };

    const setInputUpdate = () => {
        if (isAddress === '') {
            alert('주소를 검색하세요.');
            return;
        } else {
            if (isAddressDetail === '') {
                alert('상세주소를 입력하세요.');
                return;
            }
        }

        setText(isAddress + ' ' + isAddressDetail);
        setModalClose();
    };
    return (
        <Modal show={isAddressModalshow} onHide={() => setModalClose()}>
            <Modal.Header closeButton>
                <Modal.Title>
                    <h5>{isTitle}</h5>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <>
                        {isOpen ? (
                            <div>
                                <DaumPostcode theme={themeObj} style={postCodeStyle} onComplete={completeHandler} onClose={closeHandler} />
                            </div>
                        ) : (
                            <>
                                <div>{isAddress}</div>
                                {isAddress && (
                                    <div>
                                        <InputMask
                                            type="text"
                                            maxLength={50}
                                            className="mt-3 form-control"
                                            placeholder="상세주소"
                                            autoFocus
                                            onChange={(e) => {
                                                setAddressDetail(e.target.value);
                                            }}
                                            onKeyDown={(e) => activeEnter(e)}
                                            autocomplete="off"
                                        />
                                    </div>
                                )}
                            </>
                        )}
                    </>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                {isAddress && (
                    <Button
                        variant="secondary"
                        className="float-start"
                        onClick={() => {
                            setResetAddress();
                        }}
                    >
                        주소 재검색
                    </Button>
                )}
                <Button
                    variant="secondary"
                    onClick={() => {
                        setModalClose();
                    }}
                >
                    닫기
                </Button>

                <Button variant="primary" onClick={() => setInputUpdate()}>
                    입력 완료
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export { AddressModalPopup };
