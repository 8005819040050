// Loading.js
import React, { useEffect, useState, useRef } from 'react';
import { Button, CircularProgress, FormControl, IconButton, InputAdornment, OutlinedInput, TextField } from '@mui/material';
import Box from '@mui/material/Box';
import { filledInputClasses } from '@mui/material/FilledInput';
import { inputBaseClasses } from '@mui/material/InputBase';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import AspectRatioIcon from '@mui/icons-material/AspectRatio';
import { FileInputButton, FileCard } from '@files-ui/react';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { TransformWrapper, TransformComponent } from '@pronestor/react-zoom-pan-pinch';
import { AddressModalPopup } from '../../components/AddressModalPopup';
import InputMask from 'react-input-mask';
import { debounce, set } from 'lodash';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { ModalPopup } from '../../components/ModalPopup';
import Button2 from 'react-bootstrap/Button';
import { isMobile } from 'react-device-detect';
import dayjs, { Dayjs } from 'dayjs';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
// import 'moment/locale/ko';
// import { koKR } from '@mui/x-date-pickers/locales';
import 'dayjs/locale/ko';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import './intro.css';
import rainbow_logo_big from '../../assets/images/rainbow_bg.png';
import rainbow_logo from '../../assets/images/rainbow-logo3.png';
import rainbow_logo_small from '../../assets/images/android-icon-72x72.png';
import somefile_00001 from '../../file_001.png';
import somefile_00002 from '../../file_002.png';
import somefile_00003 from '../../file_003.png';
import somefile_00004 from '../../file_004.png';

import apiClient from '../../apiClient';
import { Card, Dropdown, DropdownButton, Form, InputGroup, Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const AgreementSignPage = () => {
    const today = new Date();
    const canvasRef = useRef();
    const transformComponentRef = useRef();

    const [isPage, setPage] = useState(1);
    const [isTitle, setTitle] = useState('');
    const [isInputStatus, setInputStatus] = useState('');
    const [isInputValue, setInputValue] = useState('');
    const [isInputForm, setInputForm] = useState(true);
    const [isSigned, setIsSigned] = useState(false);
    const [isModalshow, setModalShow] = useState(false);
    const [isRegister, setRegister] = useState('');

    const [isAgreementCheck, setAgreementCheck] = useState(false);
    const [isAgreement1, setAgreement1] = useState(false);

    const [isLoaded, setLoaded] = useState(false);
    const [isAddressModalshow, setAddressModalshow] = useState(false); //우편번호 팝업

    const divWrapRef = useRef(null);
    const imgWrap1Ref = useRef(null);
    const imgWrap2Ref = useRef(null);
    const imgWrap3Ref = useRef(null);
    const imgWrap4Ref = useRef(null);

    const [marginX, setMarginX] = useState('0');
    const [marginY, setMarginY] = useState('0');

    const [isName, setName] = useState('');
    const [isPhone, setPhone] = useState('');
    const [isJumin, setJumin] = useState('');
    const [isEmployDate, setEmployDate] = useState('');
    const [isEmail, setEmail] = useState('');
    const [isEmailDomain, setEmailDomain] = useState('');
    const [isEmailCheck, setEmailCheck] = useState(false);
    const [isAddress, setAddress] = useState('');
    const [isSign, setSign] = useState('');
    const [isHour, setHour] = useState('');
    const [isProgress, setProgress] = useState(false);
    const [isVerifyPhone, setVerifyPhone] = useState('');

    const [isFiles, setFiles] = useState([]);

    const zoomIn = () => {
        if (transformComponentRef.current) {
            const { zoomIn } = transformComponentRef.current;
            zoomIn(0.5);
        }
    };

    const zoomOut = () => {
        if (transformComponentRef.current) {
            const { zoomOut } = transformComponentRef.current;
            zoomOut(0.5);
        }
    };

    const updateFiles = (incommingFiles) => {
        setFiles(incommingFiles);
    };

    const reset = () => {
        if (transformComponentRef.current) {
            const { resetTransform } = transformComponentRef.current;
            resetTransform();
        }
    };

    const setInputFormUpdate = (flag) => {
        if (isName === '') {
            alert('이름을 입력하세요.');
            return;
        }

        if (isJumin === '') {
            alert('주민번호를 입력하세요.');
            return;
        }

        if (isAddress === '') {
            alert('주소를 입력하세요.');
            return;
        }

        if (isEmail === '') {
            alert('이메일 주소를 입력하세요.');
            return;
        }

        if (isEmailDomain === '') {
            alert('이메일 주소를 입력하세요.');
            return;
        }

        if (!checkEmail(isEmail + '@' + isEmailDomain)) {
            alert('이메일 형식이 올바르지 않습니다.');
            return;
        }

        if (isNaN(dayjs(isEmployDate).year()) || isNaN(dayjs(isEmployDate).month()) || isNaN(dayjs(isEmployDate).date())) {
            alert('입사일을 선택 입력하세요.');
            return;
        }

        if (dayjs(isEmployDate).year() === '' || dayjs(isEmployDate).month() === '' || dayjs(isEmployDate).date() === '') {
            alert('입사일을 선택 입력하세요.');
            return;
        }

        if (!isHour) {
            alert('일 근무시간을 입력하세요.');
            return;
        }

        if (isSign === '') {
            alert('서명을 입력하세요.');
            return;
        }

        if (!isAgreementCheck) {
            alert('개인정보 수집 · 이용에 동의 하셔야 진행이 가능합니다.');
            return;
        }

        setInputForm(flag);
        handleResize();
    };

    function checkPage(pg, gubun) {
        if (pg === 5 && gubun !== 'prev') {
            mailSend();
            return;
        }

        if (pg === 1 && gubun === 'prev') {
            //alert();
            setInputForm(true);
            setLoaded(false);
            return;
        }

        if (gubun === 'next') {
            setPage(pg + 1);
        } else {
            setPage(pg - 1);
        }
    }

    const mailSend = async (e) => {
        if (!isName) {
            alert('정상적인 접속경로가 아닙니다. 새로고침 후 다시 진행해주세요.');
            return;
        }

        if (!isPhone) {
            alert('정상적인 접속경로가 아닙니다. 새로고침 후 다시 진행해주세요.');
            return;
        }

        if (isFiles.length === 0) {
            alert('근로계약에 필요한 서류를 업로드 하세요.\n(신분증, 주민등록등본)');
            return;
        }
        if (isFiles.length > 4) {
            alert('첨부파일은 최대 2개까지만 허용 됩니다.');
            return;
        }

        let fileSizeCheck = false;
        let fileTypeCheck = false;
        isFiles.map((file) => {
            if (file.size > 1024 * 1000 * 5) {
                fileSizeCheck = true;
                alert('[' + file.name + '] 파일은 첨부 하실수 없습니다.\n\n(파일당 사이즈 5MB이하 허용)');
            }

            let ext = file.name.split('.').pop().toLowerCase();
            var extArray = ['bmp', 'png', 'gif', 'pdf', 'jpg', 'jpeg'];
            if (extArray.indexOf(ext) == -1) {
                alert('등록 할수 없는 파일 형식입니다. PDF/이미지 파일만 가능합니다.');
                fileTypeCheck = true;
            }
        });

        if (fileSizeCheck || fileTypeCheck) {
            return;
        }

        setProgress(true);
        setRegister('');
        document.body.style.overflow = 'hidden';
        document.querySelector('#page-1').style.setProperty('display', `block`);
        const files = await getFile();
        let axiosConfig = {
            headers: {},
        };

        const formData = new FormData();
        formData.append('name', isName);
        formData.append('phone', isPhone.replace(/-/g, ''));
        formData.append('email', isEmail + '@' + isEmailDomain);
        formData.append('files', files);
        isFiles.forEach((item) => {
            formData.append('files', item.file);
        });

        apiClient
            .post('/agreement/emailSend', formData, axiosConfig)
            .then((res) => {
                if (res.data.status === 'ok') {
                    setRegister('ok');
                } else if (res.data.status === 'fail') {
                    setRegister('fail');
                } else {
                    if (res.data.message === 'File too large') {
                        setRegister('fail-filelarge');
                    } else if (res.data.message === 'Invalid file type') {
                        setRegister('fail-invalidfile');
                    }
                }
            })
            .catch((err) => {
                setRegister('fail');
            });

        document.body.style.overflow = 'unset';
    };

    const getFile = async () => {
        const pdf_layer = document.querySelector('#pdf-layer');
        const canvas_option = {
            scale: isMobile ? 8 : 4,
            width: pdf_layer.offsetWidth,
            height: pdf_layer.offsetHeight,
        };
        let doc = new jsPDF('p', 'mm', 'a4');
        let pageWidth = doc.internal.pageSize.getWidth();

        document.querySelector('#page-1').style.setProperty('display', `none`);
        document.querySelector('#page-2').style.setProperty('display', `none`);
        document.querySelector('#page-3').style.setProperty('display', `none`);
        document.querySelector('#page-4').style.setProperty('display', `none`);

        document.querySelector('#page-1').style.setProperty('display', `block`);
        if (imgWrap1Ref.current) {
            let canvas = await html2canvas(pdf_layer, canvas_option);
            let widthRatio = pageWidth / canvas.width;
            let customHeight = canvas.height * widthRatio;
            let imageFile = canvas.toDataURL('image/png');
            doc.addImage(imageFile, 'png', 0, 0, pageWidth, customHeight, '', 'FAST');
            document.querySelector('#page-1').style.setProperty('display', `none`);
            document.querySelector('#page-2').style.setProperty('display', `block`);

            canvas = await html2canvas(pdf_layer, canvas_option);
            imageFile = canvas.toDataURL('image/png');
            doc.addPage();
            doc.addImage(imageFile, 'png', 0, 0, pageWidth, customHeight, '', 'FAST');
            document.querySelector('#page-2').style.setProperty('display', `none`);

            document.querySelector('#page-3').style.setProperty('display', `block`);
            canvas = await html2canvas(pdf_layer, canvas_option);
            imageFile = canvas.toDataURL('image/png');
            doc.addPage();
            doc.addImage(imageFile, 'png', 0, 0, pageWidth, customHeight, '', 'FAST');
            document.querySelector('#page-3').style.setProperty('display', `none`);

            document.querySelector('#page-4').style.setProperty('display', `block`);
            canvas = await html2canvas(pdf_layer, canvas_option);
            imageFile = canvas.toDataURL('image/png');
            doc.addPage();
            doc.addImage(imageFile, 'png', 0, 0, pageWidth, customHeight, '', 'FAST');
            //document.querySelector('#page-4').style.setProperty('display', `none`);
        }

        document.querySelector('#page-1').style.setProperty('display', `none`);
        document.querySelector('#page-2').style.setProperty('display', `none`);
        document.querySelector('#page-3').style.setProperty('display', `none`);
        document.querySelector('#page-4').style.setProperty('display', `none`);

        return new File([doc.output('blob')], `rainbow_sendfile.pdf`, {
            type: 'application/pdf',
        });
    };

    useEffect(() => {
        if (transformComponentRef.current) {
            const { zoomToElement } = transformComponentRef.current;
            zoomToElement('pdf-layer', 1);
        }
    }, [isPage]);

    useEffect(() => {
        if (divWrapRef.current) {
            imgWrap1Ref.current.style.setProperty('transform', `scale(${divWrapRef.current.offsetWidth / 1024})`);
            imgWrap2Ref.current.style.setProperty('transform', `scale(${divWrapRef.current.offsetWidth / 1024})`);
            imgWrap3Ref.current.style.setProperty('transform', `scale(${divWrapRef.current.offsetWidth / 1024})`);
            imgWrap4Ref.current.style.setProperty('transform', `scale(${divWrapRef.current.offsetWidth / 1024})`);

            setMarginX(divWrapRef.current.offsetWidth - 1024);
            setMarginY(1447.39 * (divWrapRef.current.offsetWidth / 1024) - 1447.39);
            setLoaded(true);
        }
    }, []);

    const handleResize = debounce(() => {
        if (divWrapRef.current) {
            imgWrap1Ref.current.style.setProperty('transform', `scale(${divWrapRef.current.offsetWidth / 1024})`);
            imgWrap2Ref.current.style.setProperty('transform', `scale(${divWrapRef.current.offsetWidth / 1024})`);
            imgWrap3Ref.current.style.setProperty('transform', `scale(${divWrapRef.current.offsetWidth / 1024})`);
            imgWrap4Ref.current.style.setProperty('transform', `scale(${divWrapRef.current.offsetWidth / 1024})`);

            setMarginX(divWrapRef.current.offsetWidth - 1024);
            setMarginY(1447.39 * (divWrapRef.current.offsetWidth / 1024) - 1447.39);
            setLoaded(true);
        }
    }, 200);

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    //팝업 타이틀 메시지
    const handleShow = (val, msg) => {
        setTitle(msg);
        setInputValue('');
        if (val === 'sign') {
            // isMobile && document.querySelector('.agree_wrap').style.setProperty('display', `none`);

            setIsSigned(true);
        } else {
            setIsSigned(false);
        }
        setInputStatus(val);

        if (val === 'address') {
            setAddressModalshow(true);
        } else {
            setModalShow(true);
        }
    };

    //팝업 닫을때 이벤트
    const handleClose = () => {
        setModalShow(false);

        if (isInputStatus === 'sign') {
            // isMobile && document.querySelector('.agree_wrap').style.setProperty('display', `block`);
        }
    };

    //팝업 닫을때 이벤트
    const handleAddressClose = () => {
        setAddressModalshow(false);
    };

    const checkEmail = (email) => {
        if (/.+@.+\.[A-Za-z]+$/.test(email)) {
            return true;
        } else {
            return false;
        }

        //setEmail(email);
    };

    const setUpdate = () => {
        if (isInputStatus === 'sign') {
            //isMobile && document.querySelector('.agree_wrap').style.setProperty('display', `block`);
            setSign(isInputValue);
        }
        setModalShow(false);
    };

    const removeFile = (id) => {
        setFiles(isFiles.filter((x) => x.id !== id));
    };

    useEffect(() => {
        const phone = localStorage.getItem(`phone`);

        const payload = { phone: phone };
        apiClient
            .post('/agreement/verifyp', payload)
            .then((res) => {
                if (res.data.status == 'fail') {
                    if (res.data.message == 'error') {
                        alert('세션이 종료 되어 처음 인증화면으로 이동합니다.');
                        window.location.href = '/agreement';
                        return;
                    }
                } else {
                    setPhone(phone);
                    setVerifyPhone(phone + '번호로 본인 확인 인증된 문서입니다. (인증 시간 : ' + res.data.message + ')');
                }
            })
            .catch((err) => {
                alert('시스템 오류가 발생했습니다. 관리자에게 문의하세요.');
                window.location.href = '/agreement';
                return;
            });
    }, []);

    const resetPage = () => {
        setProgress(false);
        setRegister('');
        setPage(3);
    };

    const handleHourTime = (event) => {
        const value = event.target.value;
        if (value >= 0 && value <= 24) {
            setHour(value);
        }
    };

    const handleEmailDomain = (event) => {
        const value = event.target.text;
        setEmailDomain(value);
        //checkEmailMask(isEmail + '@' + isEmailDomain);
    };

    const handleEmailDomainClear = () => {
        setEmailDomain('');
    };

    return (
        <>
            {isProgress && (
                <div
                    style={{
                        position: 'absolute',
                        width: '100%',
                        height: '100vh',
                        backgroundColor: '#484848',
                        top: 0,
                        zIndex: 99999,
                    }}
                    className="pt-5"
                >
                    <div className="card text-center mx-auto p-4 mt-5" style={{ width: '20rem' }}>
                        <img src={rainbow_logo_big} width="150px" className="mx-auto p-3" alt="" />
                        <div className="card-body p-2">
                            <div>
                                {isRegister === '' ? (
                                    <>
                                        <CircularProgress style={{ width: '70px', height: '70px' }} />

                                        <p className="card-text mt-2 pt-2">
                                            <span className="fw-bold">근로계약서 제출중입니다.</span>
                                            <br />
                                            <span className="fw-bold">
                                                잠시만 기다려주세요.
                                                <br />약 20초정도 소요됩니다.
                                            </span>
                                            <br />
                                            <br />
                                            창을 닫거나 페이지를 벗어날 경우
                                            <br />
                                            제출이 실패할수 있습니다.
                                            {/* <div onClick={() => setProgress(false)}>aaa</div> */}
                                        </p>
                                    </>
                                ) : (
                                    <>
                                        {isRegister === 'ok' ? (
                                            <>
                                                <CheckCircleOutlineIcon className="text-primary" style={{ width: '90px', height: '90px' }} />

                                                <p className="card-text mt-2 pt-2">
                                                    <span className="fw-bold">근로계약서 제출이 완료 되었습니다.</span>
                                                    <br />
                                                    <br />
                                                    감사합니다.
                                                </p>
                                                <Button
                                                    variant="contained"
                                                    color="warning"
                                                    className="mb-2 mt-2 py-2 px-4 me-1"
                                                    onClick={() => {
                                                        window.location.href = '/agreement/';
                                                    }}
                                                >
                                                    <span className="h6 p-0 m-0 fw-bold text-white">처음으로 돌아가기</span>
                                                </Button>
                                            </>
                                        ) : (
                                            <>
                                                <ErrorOutlineIcon className="me-2 text-danger" style={{ width: '90px', height: '90px' }} />

                                                <p className="card-text mt-2 pt-2">
                                                    <span className="fw-bold text-danger d-block">근로계약서 제출 실패</span>
                                                    <span className="fw-bold text-danger ">
                                                        {isRegister === 'fail-filelarge' && '(업로드 허용 용량 파일당 5MB)'}
                                                        {isRegister === 'fail-invalidfile' && '(PDF/이미지 파일만 업로드 가능)'}
                                                    </span>
                                                    <br />
                                                    <br />
                                                    제출이 계속 실패할 경우
                                                    <br />
                                                    담당자에게 문의하세요.
                                                </p>
                                                <Button
                                                    variant="contained"
                                                    color="warning"
                                                    className="mb-2 mt-2 py-2 px-4 me-1"
                                                    onClick={() => {
                                                        resetPage();
                                                    }}
                                                >
                                                    <span className="h6 p-0 m-0 fw-bold">이전 화면으로 이동</span>
                                                </Button>
                                            </>
                                        )}
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            )}
            <div className="page-content agreement-grid">
                {isInputForm == true ? (
                    <div className="container text-center mt-3">
                        <div className="row text-center">
                            <div className="col-12 col-lg-6 col-xl-5 col-xxl-5 mx-auto">
                                <Card className="w-100">
                                    <Card.Body>
                                        <img src={rainbow_logo} />
                                        <Card.Title className="fs-4 fw-bold mt-2">기본 정보 입력</Card.Title>
                                        <Card.Text>
                                            <div className="mb-2 row">
                                                <div>
                                                    <InputMask
                                                        type="text"
                                                        maskPlaceholder={''}
                                                        maxLength={20}
                                                        placeholder="이름을 입력하세요"
                                                        autoFocus
                                                        onChange={(e) => {
                                                            setName(e.target.value);
                                                        }}
                                                        autocomplete="off"
                                                        className="form-control py-2 cus-focus-line"
                                                        value={isName}
                                                    />
                                                </div>
                                            </div>
                                            <div className="mb-2 row">
                                                <div>
                                                    <InputMask
                                                        type="tel"
                                                        mask={'999999-9999999'}
                                                        // maxLength={13}
                                                        placeholder="주민번호를 입력하세요"
                                                        onChange={(e) => {
                                                            setJumin(e.target.value);
                                                        }}
                                                        // onKeyDown={(e) => activeEnter(e)}
                                                        autocomplete="off"
                                                        className="form-control py-2 cus-focus-line"
                                                        value={isJumin}
                                                    />
                                                </div>
                                            </div>
                                            <div className="mb-2 row">
                                                <div>
                                                    <InputMask type="text" placeholder="주소를 입력하세요" autocomplete="off" className="form-control py-2 cus-focus-line" value={isAddress} onClick={() => handleShow('address', '주소를 입력하세요.')} />
                                                </div>
                                            </div>
                                            <div className="mb-2 row">
                                                <div>
                                                    <div className="input-group">
                                                        <input type="text" className="form-control py-2 cus-focus-line" placeholder="이메일" value={isEmail} onChange={(e) => setEmail(e.target.value)} />
                                                        <span className="input-group-text">@</span>
                                                        <input type="text" className="form-control py-2 cus-focus-line" placeholder="직접입력" value={isEmailDomain} onChange={(e) => setEmailDomain(e.target.value)} />
                                                        <button type="button" className="btn btn-outline-secondary dropdown-toggle dropdown-toggle-split" data-bs-toggle="dropdown" aria-expanded="false">
                                                            <span></span>
                                                        </button>
                                                        <ul className="dropdown-menu dropdown-menu-end">
                                                            <li>
                                                                <Link className="dropdown-item" onClick={handleEmailDomain}>
                                                                    naver.com
                                                                </Link>
                                                            </li>
                                                            <li>
                                                                <Link className="dropdown-item" onClick={handleEmailDomain}>
                                                                    gmail.com
                                                                </Link>
                                                            </li>
                                                            <li>
                                                                <Link className="dropdown-item" onClick={handleEmailDomain}>
                                                                    daum.com
                                                                </Link>
                                                            </li>
                                                            <li>
                                                                <Link className="dropdown-item" onClick={handleEmailDomain}>
                                                                    hanmail.com
                                                                </Link>
                                                            </li>
                                                            <li>
                                                                <Link className="dropdown-item" onClick={handleEmailDomain}>
                                                                    kakao.com
                                                                </Link>
                                                            </li>
                                                            <li>
                                                                <Link className="dropdown-item" onClick={handleEmailDomain}>
                                                                    nate.com
                                                                </Link>
                                                            </li>
                                                            <li>
                                                                <Link className="dropdown-item" onClick={handleEmailDomain}>
                                                                    tistory.com
                                                                </Link>
                                                            </li>
                                                            <li>
                                                                <Link className="dropdown-item" onClick={handleEmailDomain}>
                                                                    yahoo.com
                                                                </Link>
                                                            </li>
                                                            <li>
                                                                <hr className="dropdown-divider" />
                                                            </li>
                                                            <li>
                                                                <Link className="dropdown-item" onClick={handleEmailDomainClear}>
                                                                    직접입력
                                                                </Link>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    {/* <div className="text-start text-danger mt-1">{}* {isEmail && isEmailDomain ? isEmail + '@' + isEmailDomain : ''}</div> */}
                                                    {/* <InputMask type="email" onChange={(e) => checkEmailMask(e.target.value)} placeholder="이메일을 입력하세요." autocomplete="off" className="form-control py-2 cus-focus-line" value={isEmail} /> */}
                                                </div>
                                            </div>
                                            <div className="mb-2 row gx-0">
                                                {/* localeText={koKR.components.MuiLocalizationProvider.defaultProps.localeText} dateFormats={{ monthAndYear: 'YYYY년 MM월' }} */}
                                                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'ko'}>
                                                    {/* <DatePicker label="입사일을 선택입력하세요." value={dayjs(isEmployDate)} onChange={setEmployDate} slotProps={{ toolbar: { hidden: true }, textField: { placeholder: '입사일을 선택하세요.' } }} className="form-control fs-4 fw-bold text-dark" /> */}
                                                    <DatePicker variant="filled" label="입사일을 선택하세요&nbsp;&nbsp;&nbsp;" value={isEmployDate ? dayjs(isEmployDate) : null} onChange={setEmployDate} format="YYYY년 MM월 DD일" slotProps={{ toolbar: { hidden: true } }} />
                                                </LocalizationProvider>
                                            </div>
                                            <div className="mb-2 row gx-0 gy-0">
                                                <TextField
                                                    label="일 근무시간을 입력하세요&nbsp;&nbsp;&nbsp;"
                                                    variant="outlined"
                                                    type="number"
                                                    InputProps={{
                                                        inputProps: {
                                                            min: 0,
                                                            max: 24,
                                                        },
                                                    }}
                                                    onChange={handleHourTime}
                                                    value={isHour}
                                                    // slotProps={{
                                                    //     input: {
                                                    //         endAdornment: (
                                                    //             <InputAdornment
                                                    //                 position="end"
                                                    //                 sx={{
                                                    //                     opacity: 1,
                                                    //                     pointerEvents: 'none',
                                                    //                     [`[data-shrink=true] ~ .${inputBaseClasses.root} > &`]: {
                                                    //                         opacity: 1,
                                                    //                     },
                                                    //                 }}
                                                    //             >
                                                    //                 시간
                                                    //             </InputAdornment>
                                                    //         ),
                                                    //     },
                                                    // }}
                                                />
                                            </div>
                                            <div className="row mb-1 gx-0">
                                                <div className="card border-1 gx-0 mb-0" style={{ border: '1px solid #ced4da' }}>
                                                    <h5 className="card-header fs-4">서명 입력</h5>
                                                    <div className="card-body text-center">
                                                        {isSign ? (
                                                            <>
                                                                <img
                                                                    src={isSign}
                                                                    alt="sign"
                                                                    style={{
                                                                        width: '30%',
                                                                    }}
                                                                    className="mx-auto d-block"
                                                                />

                                                                <button type="button" className="btn btn-info mt-3 w-100" onClick={() => handleShow('sign', '서명')}>
                                                                    다시입력
                                                                </button>
                                                            </>
                                                        ) : (
                                                            <>
                                                                <p className="card-text">근로계약서에 사용할 서명을 입력하세요</p>
                                                                <button type="button" className="btn btn-info" onClick={() => handleShow('sign', '서명')}>
                                                                    서명입력
                                                                </button>
                                                            </>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="text-start mb-1 mt-2">
                                                <div className="form-check">
                                                    <input className="form-check-input mt-0" type="checkbox" id="agreeCheck" checked={isAgreementCheck} onChange={({ target: { checked } }) => setAgreementCheck(checked)} />
                                                    <label className="form-check-label align-bottom me-1" htmlFor="agreeCheck">
                                                        개인정보 수집 · 이용 동의
                                                    </label>
                                                    <span onClick={() => setAgreement1(true)} style={{ cursor: 'pointer' }}>
                                                        [내용보기]
                                                    </span>
                                                </div>
                                            </div>
                                        </Card.Text>
                                        <button type="button" className="btn btn-primary ps-4 pe-4 w-100 mt-2" onClick={() => setInputFormUpdate(false)}>
                                            다음
                                        </button>
                                    </Card.Body>
                                </Card>
                            </div>
                        </div>
                    </div>
                ) : (
                    <>
                        <nav className={`navbar fixed-top bg-dark p-2`}>
                            <div className="d-flex flex-row w-100">
                                <div className="flex-fill">
                                    <img src={rainbow_logo_small} width="30px" style={{ marginTop: '-2px' }} />
                                    <span className="text-white fw-bold h6 ms-1">{isPage === 5 ? <>서류제출</> : <>Page {isPage}/4</>}</span>
                                </div>
                                <div className={`flex-fill text-end ${isPage <= 3 ? '' : 'd-none'}`}>
                                    <IconButton
                                        color="warning"
                                        size="large"
                                        className="p-0"
                                        onClick={() => {
                                            zoomIn();
                                        }}
                                    >
                                        <AddCircleOutlineIcon fontSize="inherit" />
                                    </IconButton>
                                    <IconButton
                                        color="warning"
                                        size="large"
                                        className="p-0 ps-2"
                                        onClick={() => {
                                            zoomOut();
                                        }}
                                    >
                                        <RemoveCircleOutlineIcon fontSize="inherit" />
                                    </IconButton>
                                    <IconButton
                                        color="primary"
                                        size="large"
                                        className="p-0 ps-2"
                                        onClick={() => {
                                            reset();
                                        }}
                                    >
                                        <AspectRatioIcon fontSize="inherit" />
                                    </IconButton>
                                </div>
                            </div>
                        </nav>
                        <div className="container pb-3 mt-5">
                            <div className="row pb-5 ">
                                <div className="col-12 mx-auto pb-2">
                                    {/* <div className="col-12 col-lg-7 col-xl-7 col-xxl-7 mx-auto pt-4 pb-4"> */}
                                    <div className="text-center" ref={divWrapRef}>
                                        <div className={`${isPage === 5 ? '' : 'd-none'}`}>
                                            <div className="pb-3">
                                                <p className="text-center text-white p-0 m-0 pb-0 pt-3 h3">추가 서류를 업로드하세요.</p>
                                                <p className="text-center text-white p-0 m-0 pb-0 pt-3 h4">1. 신분증(전면)</p>
                                                <p className="text-center text-white p-0 m-0 pb-0 pt-2 h4">2. 주민등록등본</p>
                                                <p className="text-center text-white p-0 pb-1 pt-2">최대 2개, 이미지 파일만 가능합니다.</p>
                                                <FileInputButton onChange={updateFiles} value={isFiles} accept={'image/*'} className="mt-3">
                                                    <FileUploadIcon className="me-2" />
                                                    파일 선택
                                                </FileInputButton>
                                            </div>
                                            {isFiles.length > 0 && (
                                                <div className="d-flex flex-column justify-content-center align-items-center gap-0 row-gap-2">
                                                    {isFiles.map((file) => (
                                                        <FileCard key={file.id} {...file} onDelete={removeFile} valid={undefined} preview smartImgFit={'center'} />
                                                    ))}
                                                </div>
                                            )}
                                        </div>

                                        <TransformWrapper
                                            initialScale={1}
                                            initialPositionX={0}
                                            initialPositionY={0}
                                            alignmentAnimation={{ sizeX: 0, sizeY: 0 }}
                                            // limitToBounds={true}
                                            // centerOnInit={false}
                                            wheel={{
                                                disabled: true,
                                            }}
                                            // doubleClick={{
                                            //     mode: 'reset',
                                            // }}
                                            ref={transformComponentRef}
                                        >
                                            {({ zoomIn, zoomOut, resetTransform, zoomToElement }) => (
                                                <>
                                                    <div
                                                        style={{
                                                            textAlign: 'center',
                                                            overflow: 'hidden',
                                                            display: 'block',
                                                        }}
                                                    >
                                                        <TransformComponent>
                                                            <div
                                                                style={{
                                                                    width: '100%',
                                                                    display: 'inline-block',
                                                                }}
                                                                id="pdf-layer"
                                                            >
                                                                {/* 1번 */}
                                                                <div
                                                                    id="page-1"
                                                                    ref={imgWrap1Ref}
                                                                    className="warp_container"
                                                                    style={{
                                                                        display: isLoaded ? (isPage === 1 ? 'block' : 'none') : 'none',
                                                                        marginRight: marginX,
                                                                        marginBottom: marginY,
                                                                    }}
                                                                >
                                                                    <img src={somefile_00001} width="1024px" height="1447.39px" />
                                                                    <div id="isName">
                                                                        <div>{isName}</div>
                                                                    </div>
                                                                    <div id="isPhone">
                                                                        <div>{isPhone}</div>
                                                                    </div>
                                                                    <div id="isJumin">
                                                                        <div>{isJumin}</div>
                                                                    </div>
                                                                    <div id="isEmployDate_y">
                                                                        <div>{dayjs(isEmployDate).year()}</div>
                                                                    </div>
                                                                    <div id="isEmployDate_m">
                                                                        <div>{dayjs(isEmployDate).month() + 1}</div>
                                                                    </div>
                                                                    <div id="isEmployDate_d">
                                                                        <div>{dayjs(isEmployDate).date()}</div>
                                                                    </div>
                                                                    <div id="isAddress">
                                                                        <div>{isAddress}</div>
                                                                    </div>
                                                                    <div id="isSign-1">
                                                                        <div>
                                                                            <img
                                                                                src={isSign}
                                                                                alt="sign"
                                                                                style={{
                                                                                    width: '100%',
                                                                                }}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div id="isSign-2">
                                                                        <div>
                                                                            <img
                                                                                src={isSign}
                                                                                alt="sign"
                                                                                style={{
                                                                                    width: '100%',
                                                                                }}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="verifyphone">{isVerifyPhone}</div>
                                                                </div>

                                                                {/* 2번 */}
                                                                <div
                                                                    id="page-2"
                                                                    ref={imgWrap2Ref}
                                                                    className="warp_container"
                                                                    style={{
                                                                        display: isLoaded ? (isPage === 2 ? 'block' : 'none') : 'none',
                                                                        marginRight: marginX,
                                                                        marginBottom: marginY,
                                                                    }}
                                                                >
                                                                    <img src={somefile_00002} width="1024px" height="1447.39px" />
                                                                    <div id="isSign-1">
                                                                        <div>
                                                                            <img
                                                                                src={isSign}
                                                                                alt="sign"
                                                                                style={{
                                                                                    width: '100%',
                                                                                }}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div id="year">{today.getFullYear().toString().substring(0, 4)}</div>
                                                                    <div id="month">{today.getMonth() + 1}</div>
                                                                    <div id="day">{today.getDate()}</div>
                                                                    <div id="isName">
                                                                        <div>{isName}</div>
                                                                    </div>
                                                                    <div id="isSign">
                                                                        <div>
                                                                            <img
                                                                                src={isSign}
                                                                                alt="sign"
                                                                                style={{
                                                                                    width: '100%',
                                                                                }}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="verifyphone">{isVerifyPhone}</div>
                                                                </div>

                                                                {/* 3번 */}
                                                                <div
                                                                    id="page-3"
                                                                    ref={imgWrap3Ref}
                                                                    className="warp_container"
                                                                    style={{
                                                                        display: isLoaded ? (isPage === 3 ? 'block' : 'none') : 'none',
                                                                        marginRight: marginX,
                                                                        marginBottom: marginY,
                                                                    }}
                                                                >
                                                                    <img src={somefile_00003} width="1024px" height="1447.39px" />

                                                                    <div id="isName">
                                                                        <div>{isName}</div>
                                                                    </div>
                                                                    <div id="isJumin">
                                                                        <div>{isJumin}</div>
                                                                    </div>
                                                                    <div id="isAddress">
                                                                        <div className={isAddress.length > 20 ? `double-line` : `single-line`}>{isAddress}</div>
                                                                    </div>
                                                                    <div id="isPhone">
                                                                        <div>{isPhone}</div>
                                                                    </div>
                                                                    <div id="isName-2">
                                                                        <div>{isName}</div>
                                                                    </div>
                                                                    <div id="isHour">
                                                                        <div>{isHour}</div>
                                                                    </div>
                                                                    <div className="verifyphone">{isVerifyPhone}</div>
                                                                </div>

                                                                {/* 4번 */}
                                                                <div
                                                                    id="page-4"
                                                                    ref={imgWrap4Ref}
                                                                    className="warp_container"
                                                                    style={{
                                                                        display: isLoaded ? (isPage === 4 ? 'block' : 'none') : 'none',
                                                                        marginRight: marginX,
                                                                        marginBottom: marginY,
                                                                    }}
                                                                >
                                                                    <img src={somefile_00004} width="1024px" height="1447.39px" />
                                                                    <div id="year">{today.getFullYear().toString().substring(0, 4)}</div>
                                                                    <div id="month">{today.getMonth() + 1}</div>
                                                                    <div id="day">{today.getDate()}</div>
                                                                    <div id="isName">{isName}</div>
                                                                    <div id="isAddress">{isAddress}</div>
                                                                    <div id="isPhone">{isPhone}</div>
                                                                    <div id="isSign">
                                                                        <div>
                                                                            <img
                                                                                src={isSign}
                                                                                alt="sign"
                                                                                style={{
                                                                                    width: '100%',
                                                                                }}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="verifyphone">{isVerifyPhone}</div>
                                                                </div>
                                                            </div>
                                                        </TransformComponent>
                                                    </div>
                                                </>
                                            )}
                                        </TransformWrapper>
                                        <div
                                            style={{
                                                display: isPage === 6 ? '' : 'none',
                                            }}
                                        >
                                            <div className="card text-center mx-auto p-3" style={{ width: '18rem' }}>
                                                <img src={rainbow_logo_big} width="230px" className="mx-auto p-3" alt="" />
                                                <div className="card-body">
                                                    <p className="card-text">
                                                        <span className="fw-bold">서류 제출이 완료 되었습니다.</span>
                                                        <br />
                                                        <br />
                                                        담당자 확인 후 접수 순서대로 연락드리겠습니다.
                                                    </p>
                                                    <Button
                                                        variant="contained"
                                                        color="warning"
                                                        className="mb-2 mt-2 py-2 px-4 me-1"
                                                        onClick={() => {
                                                            window.location.href = '/';
                                                        }}
                                                    >
                                                        <span className="h6 p-0 m-0 fw-bold">처음으로 돌아가기</span>
                                                    </Button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <footer className={`bg-dark position-fixed text-white w-100 text-center bottom-0 py-1 ${isPage <= 5 ? '' : 'd-none'}`}>
                            <Button
                                variant="contained"
                                color="warning"
                                className="mb-2 mt-1 py-2 px-4 me-1"
                                style={{ textTransform: 'none' }}
                                //style={isPage > 1 ? {} : { background: '#eaeaea', color: '#c0c0c0' }}
                                //disabled={isPage > 1 ? false : true}
                                onClick={() => {
                                    checkPage(isPage, 'prev');
                                }}
                            >
                                <ArrowBackIosIcon fontSize="medium" />
                                <span className="h5 p-0 m-0 fw-bold text-white">이전</span>
                            </Button>
                            <Button
                                variant="contained"
                                color="warning"
                                className="mb-2 mt-1 py-2 px-4 ms-1"
                                style={{ textTransform: 'none' }}
                                onClick={() => {
                                    checkPage(isPage, 'next');
                                }}
                            >
                                <span className="h5 p-0 m-0 fw-bold text-white">{isPage === 5 ? '최종제출' : '다음'}</span>

                                <ArrowForwardIosIcon fontSize="medium" />
                            </Button>
                        </footer>
                    </>
                )}
            </div>
            <ModalPopup isModalshow={isModalshow} isTitle={isTitle} setText={setInputValue} isInputStatus={isInputStatus} setModalClose={handleClose} setUpdate={setUpdate} isSigned={isSigned} canvasRef={canvasRef} />
            <AddressModalPopup isAddressModalshow={isAddressModalshow} isTitle={isTitle} setText={setAddress} setModalClose={handleAddressClose} />

            <Modal size="sm" show={isAgreement1} onHide={() => setAgreementClose1()} centered>
                <Modal.Body>
                    <div className="mt-1 mb-2 fw-bold fs-4">개인정보 제공 동의서</div>
                    <div className="row gx-0 mt-1 overflow-auto" style={{ height: '200px', border: '1px solid #ced4da' }}>
                        <div className="p-2">
                            <div className="text-start">
                                레인보우홀딩스(이하”회사”) (은)는 「개인정보보호법」에 따라 근로자의 개인정보 수집 · 이용에 대한 동의를 받고 있습니다. 근로자는 아래 ‘가’, ‘나’, ‘다’, ‘라’ 항목의 동의를 거부할 수 있으며 동의하지 않는 경우 동 정보를 기초로 한 회사의 인사제도 운영에 있어 불이익을 받을 수 있음을 알려드립니다.,
                                <br />
                                <br />
                                가. 개인정보
                                <table className="table table-bordered mt-1">
                                    <thead>
                                        <tr>
                                            <td width="35%" className="text-center bg-light">
                                                구분
                                            </td>
                                            <td className="text-center bg-light">내용</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td className="text-center align-middle">수집하는 개인정보 항목</td>
                                            <td className="text-start">
                                                개인식별정보(성명, 증명사진), 연락정보(주소, 전화번호, 휴대전화번호, 전자우편주소), 학력사항(재학기간, 학교, 전공, 학점), 경력사항(근무처, 근무기간, 직위, 업무), 병역사항(군별, 병과, 계급, 제대여부, 복무기간, 미필사유), 가족사항(가족관계, 성명, 연령, 학력, 직업, 근무처, 거주지), 기타사항(주거환경, 혼인여부, 보훈대상여부, 자격, 면허, 외국어능력,
                                                OA활용능력, 신장, 체중, 시력, 혈액형, 특기, 취미 등)
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="text-center align-middle">개인정보의 수집 이용 목적</td>
                                            <td className="text-start">
                                                자격 확인 및 직무 적합여부 판단
                                                <br />
                                                인사관리(인사이동, 급여지급 등) 및 4대보험 가입
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="text-center align-middle">개인정보의 보유 및 이용기간</td>
                                            <td className="text-start">위에 기재한 수집 이용의 목적을 모두 달성할 때까지 보유 이용 후, 법령에 따라 해당 개인 정보를 보존해야 하는 의무가 존재하지 않는 이상, 해당 개인정보가 불필요하게 된 것이 확인된 때에 파기</td>
                                        </tr>
                                        <tr>
                                            <td className="text-center align-middle">동의거부에 따른 불이익</td>
                                            <td className="text-start">인사제도 운영 시 정보부재로 인한 불이익</td>
                                        </tr>
                                    </tbody>
                                </table>
                                나. 고유식별정보
                                <table className="table table-bordered mt-1">
                                    <thead>
                                        <tr>
                                            <td width="35%" className="text-center bg-light">
                                                구분
                                            </td>
                                            <td className="text-center bg-light">내용</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td className="text-center align-middle">수집하는 개인정보 항목</td>
                                            <td className="text-start">주민등록번호, 외국인등록번호</td>
                                        </tr>
                                        <tr>
                                            <td className="text-center align-middle">개인정보의 수집 이용 목적</td>
                                            <td className="text-start">
                                                개인 식별의 목적
                                                <br />
                                                인사관리(인사이동, 급여지급 등) 및 4대보험 가입
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="text-center align-middle">개인정보의 보유 및 이용기간</td>
                                            <td className="text-start">위에 기재한 수집 이용의 목적을 모두 달성할 때까지 보유 이용 후, 법령에 따라 해당 개인 정보를 보존해야 하는 의무가 존재하지 않는 이상, 해당 개인정보가 불필요하게 된 것이 확인된 때에 파기</td>
                                        </tr>
                                        <tr>
                                            <td className="text-center align-middle">동의거부에 따른 불이익</td>
                                            <td className="text-start">인사제도 운영 시 정보부재로 인한 불이익</td>
                                        </tr>
                                    </tbody>
                                </table>
                                다. 민감정보
                                <table className="table table-bordered mt-1">
                                    <thead>
                                        <tr>
                                            <td width="35%" className="text-center bg-light">
                                                구분
                                            </td>
                                            <td className="text-center bg-light">내용</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td className="text-center align-middle">수집하는 개인정보 항목</td>
                                            <td className="text-start">병력, 신체장애, 국가보훈대상, 범죄경력, 기타 인사관리에 필요한 민감정보</td>
                                        </tr>
                                        <tr>
                                            <td className="text-center align-middle">개인정보의 수집 이용 목적</td>
                                            <td className="text-start">
                                                직무 적합여부 판단
                                                <br />
                                                우선채용대상자격 확인 및 정부지원금 신청
                                                <br />
                                                인사관리(인사이동, 급여지급 등)
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="text-center align-middle">개인정보의 보유 및 이용기간</td>
                                            <td className="text-start">위에 기재한 수집 이용의 목적을 모두 달성할 때까지 보유 이용 후, 법령에 따라 해당 개인 정보를 보존해야 하는 의무가 존재하지 않는 이상, 해당 개인정보가 불필요하게 된 것이 확인된 때에 파기</td>
                                        </tr>
                                        <tr>
                                            <td className="text-center align-middle">동의거부에 따른 불이익</td>
                                            <td className="text-start">인사제도 운영 시, 정보부재로 인한 불이익</td>
                                        </tr>
                                    </tbody>
                                </table>
                                라. 개인정보의 제3자 제공
                                <table className="table table-bordered mt-1">
                                    <thead>
                                        <tr>
                                            <td width="35%" className="text-center bg-light">
                                                구분
                                            </td>
                                            <td className="text-center bg-light">내용</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td className="text-center align-middle">개인정보를 제공받는 자</td>
                                            <td className="text-start">국세청(관할 지방세무서) 및 관공서(노동부, 국민연금 관리공단, 건강보험 관리공단 등), 각종 인사관리 및 노무관련 업무위탁업체, 각종 교육기관</td>
                                        </tr>
                                        <tr>
                                            <td className="text-center align-middle">제공하는 개인정보 항목</td>
                                            <td className="text-start">개인정보(성명, 주민등록번호, 주소, 성별, 직업, 전화번호, 휴대전화번호, 전자우편주소), 원천징수 및 연말정산 신고 등에 필요한 개인정보</td>
                                        </tr>
                                        <tr>
                                            <td className="text-center align-middle">
                                                제공받는 자의
                                                <br />
                                                개인정보의 수집 이용 목적
                                            </td>
                                            <td className="text-start">인사관리 및 4대보험 가입, 연말정산, 외부 교육의 목적</td>
                                        </tr>
                                        <tr>
                                            <td className="text-center align-middle">
                                                제공받는 자의
                                                <br />
                                                개인정보의 보유 및 이용기간
                                            </td>
                                            <td className="text-start">위에 기재한 수집 이용의 목적을 모두 달성할 때까지 보유 이용 후, 법령에 따라 해당 개인 정보를 보존해야 하는 의무가 존재하지 않는 이상, 해당 개인정보가 불필요하게 된 것이 확인된 때에 파기</td>
                                        </tr>
                                        <tr>
                                            <td className="text-center align-middle">동의거부에 따른 불이익</td>
                                            <td className="text-start">인사제도 운영 시 정보부재로 인한 불이익</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button2
                        variant="secondary"
                        onClick={() => {
                            setAgreement1(false);
                        }}
                    >
                        닫기
                    </Button2>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export { AgreementSignPage };
