// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
    -webkit-text-size-adjust: none;
    -moz-text-size-adjust: none;
    -ms-text-size-adjust: none;
    -o-text-size-adjust: none;
}

body {
    background-color: #484848 !important;
    -ms-content-zooming: none;
    touch-action: pan-x pan-y;
    width: 100%;
    height: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/pages/AgreementSmsPage/intro.css"],"names":[],"mappings":"AAAA;IACI,8BAA8B;IAC9B,2BAA2B;IAC3B,0BAA0B;IAC1B,yBAAyB;AAC7B;;AAEA;IACI,oCAAoC;IACpC,yBAAyB;IAEzB,yBAAyB;IACzB,WAAW;IACX,YAAY;AAChB","sourcesContent":["* {\n    -webkit-text-size-adjust: none;\n    -moz-text-size-adjust: none;\n    -ms-text-size-adjust: none;\n    -o-text-size-adjust: none;\n}\n\nbody {\n    background-color: #484848 !important;\n    -ms-content-zooming: none;\n    -ms-touch-action: pan-x pan-y;\n    touch-action: pan-x pan-y;\n    width: 100%;\n    height: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
