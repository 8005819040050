import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { ProductPage } from '../../pages/ProductPage/';
import { ProductUpdatePage } from '../../pages/ProductUpdatePage';
import { BoardPage } from '../../pages/BoardPage';
import { BoardUpdatePage } from '../../pages/BoardUpdatePage';
import { QnaBoardPage } from '../../pages/QnaBoardPage';
import { SettingPage } from '../../pages/SettingPage';
import { OrderListPage } from '../../pages/OrderListPage';
import { OrderListTotalPage } from '../../pages/OrderListTotalPage';
import { CategoryManagerPage } from '../../pages/CategoryManagerPage';
import { MemberPage } from '../../pages/MemberPage';
import { DashBoardPage } from '../../pages/DashBoardPage';
import { LoginPage } from '../../pages/LoginPage';
import PrivateRoute from '../PrivateRoute/PrivateRoute';
import PrivateOrderNoticeRoute from '../PrivateNoticeRoute/PrivateNoticeRoute';
import { OrderAdminNoticePage } from '../../pages/OrderAdminNoticePage';
import { OrderAdminNoticeUpdatePage } from '../../pages/OrderAdminNoticeUpdatePage';
import { OrderNoticePage } from '../../pages/OrderNoticePage';
import { OrderNoticeIntroPage } from '../../pages/OrderNoticeIntroPage';
import { LoginOrderAdminNoticePage } from '../../pages/LoginOrderAdminNoticePage';
import { AgreementSmsPage } from '../../pages/AgreementSmsPage';
import { AgreementLogin } from '../../pages/AgreementLogin';
import { AgreementSignPage } from '../../pages/AgreementSignPage/AgreementSignPage';

const Page404 = () => <h1>Four:oh:four</h1>;

const Layout = () => {
    const accessToken = localStorage.getItem(`accessTokenAdmin`);
    const accessToken_exp = localStorage.getItem(`accessTokenAdmin_exp`);

    const date = new Date(accessToken_exp * 1000); // 비교할 unix time
    const now = new Date(); // 현재 시간

    const diffMSec = date.getTime() - now.getTime();

    let isUserLoggedIn = accessToken && accessToken !== '' ? (diffMSec > 0 ? true : false) : false;

    return (
        <Routes>
            <Route path="/rainbow_adm/Login" element={<LoginPage />} />
            {/* <Route path="/" element={<PrivateRoute component={<DashBoardPage />} authenticated={isUserLoggedIn} />} /> */}
            <Route path="/member" element={<PrivateRoute component={<MemberPage />} authenticated={isUserLoggedIn} />} />
            <Route path="/order" element={<PrivateRoute component={<OrderListPage />} authenticated={isUserLoggedIn} />} />
            <Route path="/orderTotal" element={<PrivateRoute component={<OrderListTotalPage />} authenticated={isUserLoggedIn} />} />
            <Route path="/categoryManager" element={<PrivateRoute component={<CategoryManagerPage />} authenticated={isUserLoggedIn} />} />
            <Route path="/product" element={<PrivateRoute component={<ProductPage />} authenticated={isUserLoggedIn} />} />
            <Route path="/product/update" element={<PrivateRoute component={<ProductUpdatePage />} authenticated={isUserLoggedIn} />} />
            <Route path="/product/update/:product_code" element={<PrivateRoute component={<ProductUpdatePage />} authenticated={isUserLoggedIn} />} />
            <Route path="/board/:board_name" element={<PrivateRoute component={<BoardPage />} authenticated={isUserLoggedIn} />} />
            <Route path="/board/update/:board_name" element={<PrivateRoute component={<BoardUpdatePage />} authenticated={isUserLoggedIn} />} />
            <Route path="/board/qna" element={<PrivateRoute component={<QnaBoardPage />} authenticated={isUserLoggedIn} />} />
            <Route path="/setting" element={<PrivateRoute component={<SettingPage />} authenticated={isUserLoggedIn} />} />
            <Route path="/" element={<OrderNoticeIntroPage />} />
            <Route path="/view" element={<OrderNoticeIntroPage />} />
            <Route path="/notice" element={<OrderNoticePage />} />
            <Route path="/admin/" element={<LoginOrderAdminNoticePage />} />

            <Route path="/agreement/" element={<AgreementLogin />} />
            <Route path="/agreement/login" element={<AgreementLogin />} />
            <Route path="/agreement/sms" element={<AgreementSmsPage />} />
            <Route path="/agreement/sign" element={<AgreementSignPage />} />

            {/* <Route path="/agreement/Login" element={<AgreementLogin />} />
            <Route path="/agreement/" element={<AgreementPage />} />
            <Route path="/agreement/Sign" element={<AgreementSignPage />} /> */}

            <Route path="/admin/notice" element={<PrivateOrderNoticeRoute component={<OrderAdminNoticePage />} authenticated={isUserLoggedIn} />} />
            <Route path="/admin/noticeUpdate" element={<PrivateOrderNoticeRoute component={<OrderAdminNoticeUpdatePage />} authenticated={isUserLoggedIn} />} />
            <Route path="/admin/noticeUpdate/:noticeidx" element={<PrivateOrderNoticeRoute component={<OrderAdminNoticeUpdatePage />} authenticated={isUserLoggedIn} />} />
        </Routes>
    );
};
export default Layout;
