import React, { useRef } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Form } from 'react-bootstrap';

import SignatureCanvas from 'react-signature-canvas';

const ModalPopup = ({ isModalshow, isTitle, setText, setModalClose, setUpdate, isSigned, canvasRef }) => {
    const handleSignatureEnd = () => {
        setText(canvasRef.current.getTrimmedCanvas().toDataURL('image/png'));
    };

    return (
        <Modal size="sm" show={isModalshow} onHide={() => setModalClose()} centered>
            <Modal.Header closeButton>
                <Modal.Title>
                    <h5>{isTitle}</h5>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group className="mb-3">
                        <div>
                            <SignatureCanvas
                                ref={canvasRef}
                                canvasProps={{
                                    className: 'signatureCanvas',
                                }}
                                backgroundColor="rgba(0, 0, 0, 0)"
                                clearOnResize={false}
                                onEnd={handleSignatureEnd}
                            />
                        </div>
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    variant="secondary"
                    onClick={() => {
                        setModalClose();
                    }}
                >
                    닫기
                </Button>

                <Button
                    variant="secondary"
                    onClick={() => {
                        canvasRef.current.clear(); // 리셋
                        setText('');
                    }}
                >
                    서명 초기화
                </Button>

                <Button variant="primary" onClick={() => setUpdate()}>
                    입력 완료
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export { ModalPopup };
