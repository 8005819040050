import React, { useState, useEffect, useRef, useCallback } from 'react';
import apiClient from '../../apiClient';
import Loading from '../../components/Loading/Loading';
import 'moment/locale/ko'; // Locale Setting
import { Link, useNavigate } from 'react-router-dom';
import { Button, Dropdown, Modal } from 'react-bootstrap';
import { Chip, ImageList, ImageListItem, InputAdornment, Stack, TextField } from '@mui/material';
import { TransformWrapper, TransformComponent } from '@pronestor/react-zoom-pan-pinch';
import 'react-photo-album/rows.css';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import Companylogo from './../../assets/images/rainbow-logo3.png';
import CheckIcon from '@mui/icons-material/Check';
import EditNoteIcon from '@mui/icons-material/EditNote';
import CreateIcon from '@mui/icons-material/Create';
import XlsIcon from './../../assets/images/xls_icon.png';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import SettingsIcon from '@mui/icons-material/Settings';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import { styled } from '@mui/material/styles';
import { AccountCircle } from '@mui/icons-material';
import { ToastContainer, toast } from 'react-toastify';
import { createBrowserHistory } from 'history';

const OrderAdminNoticePage = () => {
    const divRef = useRef();
    const navigate = useNavigate();
    const [isLoading, setLoading] = useState(false);

    const [isImages, setImages] = useState([]);
    const [isCurrentIdx, setCurrentIdx] = useState();
    const [isCurrentDt, setCurrentDt] = useState();
    const [isDate, setDate] = useState([]);

    const [isSelectImage, setSelectImage] = useState('');
    const [isSelectImageIdx, setSelectImageIdx] = useState('');
    const [isSelectOriName, setSelectOriName] = useState('');
    const [isAttachFile, setAttachFile] = useState({});
    const [isNoticePopupIdx, setNoticePopupIdx] = useState('');
    const [isShowImagePopup, setShowImagePopup] = useState(false);

    async function getNoticeList(idx_) {
        if (idx_ === undefined) {
            idx_ = 0;
        } else {
            setCurrentIdx(idx_);
        }
        setLoading(true);

        let { data } = await apiClient.get(`/notice/list/` + idx_);
        if (data && data.result === true) {
            const arrayItem = [];
            const imagesArray = [];
            const dateArray = [];

            data.list.items.map((e) => {
                dateArray.push({ idx: e.idx, dt: e.dt });
                if (e.idx === data.selectedIdx) {
                    setCurrentDt(e.dt);
                }
            });

            data.imageItems.map((e, itemIdx) => {
                imagesArray.push({ id: itemIdx, image: e.file_path, origin_name: e.origin_name, notice_idx: e.notice_idx, thumnail_idx: e.thumnail_idx });
            });

            setCurrentIdx(data.selectedIdx);
            setImages(imagesArray);
            //setNoticeList(arrayItem);
            setDate(dateArray);
            setAttachFile(data.xlsFile ? data.xlsFile : '');

            try {
                const popupInfo = imagesArray.filter((item) => item.thumnail_idx === data.popupIdx);
                setNoticePopupIdx(popupInfo.length > 0 ? popupInfo[0] : '');
            } catch (e) {
                console.log(e);
            }
        }
        setLoading(false);
    }

    useEffect(() => {
        getNoticeList();
    }, []);

    const handleShowImage = (idx) => () => {
        setSelectImage(isImages[idx].image);
        setSelectOriName(isImages[idx].origin_name);
        setSelectImageIdx(idx);
    };

    const handleCloseImage = () => {
        setSelectImage('');
        setSelectOriName('');
    };

    const setMoveImage = (tp) => {
        let idx = 0;
        if (tp === 'left') {
            idx = isSelectImageIdx - 1;
        } else {
            idx = isSelectImageIdx + 1;
        }

        if (idx === -1 || isImages.length === idx) {
            return;
        }

        setSelectImage(isImages[idx].image);
        setSelectOriName(isImages[idx].origin_name);
        setSelectImageIdx(idx);
    };

    useEffect(() => {
        if (isSelectImage) {
            setShowImagePopup(true);
        } else {
            setShowImagePopup(false);
        }
    }, [isSelectImage]);

    function goNoticeUpdate(tp) {
        tp === undefined ? navigate(`/admin/noticeUpdate`) : navigate(`/admin/noticeUpdate/` + isCurrentIdx);
    }

    const fileDownload = useCallback((srcUrl, name) => {
        fetch(srcUrl, { method: 'GET' })
            .then((res) => res.blob())
            .then((blob) => {
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = decodeURIComponent(name);
                document.body.appendChild(a);
                a.click();
                setTimeout((_) => {
                    window.URL.revokeObjectURL(url);
                }, 1000);
                a.remove();
            })
            .catch((err) => {
                console.error('err', err);
            });
    }, []);

    const IOSSwitch = styled((props) => <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />)(({ theme }) => ({
        width: 42,
        height: 26,
        padding: 0,
        '& .MuiSwitch-switchBase': {
            padding: 0,
            margin: 2,
            transitionDuration: '300ms',
            '&.Mui-checked': {
                transform: 'translateX(16px)',
                color: '#fff',
                '& + .MuiSwitch-track': {
                    opacity: 1,
                    border: 0,
                    ...theme.applyStyles('dark', {
                        backgroundColor: '#2ECA45',
                    }),
                },
                '&.Mui-disabled + .MuiSwitch-track': {
                    opacity: 0.5,
                },
            },
            '&.Mui-focusVisible .MuiSwitch-thumb': {
                color: '#33cf4d',
                border: '6px solid #fff',
            },
            '&.Mui-disabled .MuiSwitch-thumb': {
                color: theme.palette.grey[100],
                ...theme.applyStyles('dark', {
                    color: theme.palette.grey[600],
                }),
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.7,
                ...theme.applyStyles('dark', {
                    opacity: 0.3,
                }),
            },
        },
        '& .MuiSwitch-thumb': {
            boxSizing: 'border-box',
            width: 22,
            height: 22,
        },
        '& .MuiSwitch-track': {
            borderRadius: 26 / 2,
            backgroundColor: '#E9E9EA',
            opacity: 1,
            transition: theme.transitions.create(['background-color'], {
                duration: 500,
            }),
            ...theme.applyStyles('dark', {
                backgroundColor: '#39393D',
            }),
        },
    }));

    const [isAgreementPwdChkYn, setAgreementPwdChkYn] = useState(false);
    const [isAgreementPwd, setAgreementPwd] = useState();
    const goAgreementSettingUpdate = async (e) => {
        const param = {
            pwdchkyn: isAgreementPwdChkYn ? 'Y' : 'N',
            pwd: isAgreementPwd,
        };

        setLoading(true);
        const { data } = await apiClient.post('/agreement/settingUpdate', param);
        if (data.result) {
            toast.success('환결설정이 저장 되었습니다.');
        } else {
            toast.error('처리도중 오류가 발생했습니다. 다시 시도해주세요.');
        }
        setLoading(false);
    };

    const [isPwdChkYn, setPwdChkYn] = useState(false);
    const [isPwd, setPwd] = useState();
    const [isPopupYn, setPopupYn] = useState(false);
    const goSettingUpdate = async (e) => {
        const param = {
            pwdchkyn: isPwdChkYn ? 'Y' : 'N',
            pwd: isPwd,
            popupyn: isPopupYn ? 'Y' : 'N',
        };

        setLoading(true);
        const { data } = await apiClient.post('/notice/settingUpdate', param);
        if (data.result) {
            toast.success('환결설정이 저장 되었습니다.');
        } else {
            toast.error('처리도중 오류가 발생했습니다. 다시 시도해주세요.');
        }
        setLoading(false);
    };

    async function getSettingInfo() {
        let { data } = await apiClient.get(`/notice/settinginfo`);
        if (data && data.result === true) {
            setPwd(data.settinginfo.pwd);
            setPwdChkYn(data.settinginfo.pwdChk_useyn === 'Y' ? true : false);
            setPopupYn(data.settinginfo.popup_useyn === 'Y' ? true : false);
        }
    }

    async function getAgreementSettingInfo() {
        let { data } = await apiClient.get(`/agreement/settinginfo`);
        if (data && data.result === true) {
            setAgreementPwd(data.settinginfo.pwd);
            setAgreementPwdChkYn(data.settinginfo.pwdChk_useyn === 'Y' ? true : false);
        }
    }

    useEffect(() => {
        getSettingInfo();
        getAgreementSettingInfo();
    }, []);

    const history = createBrowserHistory();
    history.push('/admin/notice');
    useEffect(() => {
        const listenBackEvent = () => {
            handleCloseImage();
        };
        const historyEvent = history.listen(({ action }) => {
            if (action === 'POP') {
                listenBackEvent();
            }
        });
        return historyEvent;
    }, []);
    return (
        <>
            <div className="bg-white sticky-top p-2 pt-1 pb-1 border-bottom border-1 border-secondary">
                <div className="d-flex justify-content-between">
                    <div className="bg-white lab-logo-txt">
                        <img src={Companylogo} className="notice-logo align-bottom" style={{ marginBottom: '3px' }} />
                        <span className="align-bottom text-dark h5 ms-1">Rainbow</span>
                        <span className="align-bottom h5" style={{ color: '#ef4266' }}>
                            Lab
                        </span>
                    </div>
                    <div>
                        {isAttachFile && isAttachFile ? (
                            <>
                                {/* <button onClick={() => fileDownload(`${isAttachFile.file_path}`, `${isAttachFile.origin_name}`)} className="border-0 bg-white"> */}
                                <Link to={isAttachFile.file_path} download={isAttachFile.origin_name}>
                                    <img src={XlsIcon} className="notice-logo" /> <span className="text-dark">발주서</span>
                                </Link>
                                {/* </button> */}
                            </>
                        ) : (
                            ''
                        )}
                    </div>
                </div>
            </div>
            <div className="container-fluid h-100 pb-4" ref={divRef}>
                <div className="gallery-list">
                    <div className="row justify-content-md-start p-2">
                        <ImageList variant="" cols={3} gap={7}>
                            {isImages.map((item, idx) => (
                                <>
                                    <ImageListItem key={item.image}>
                                        <img srcSet={`${item.image}`} src={`${item.image}`} alt={item.title} loading="lazy" className={`rounded border border-2 border-dark gallery-img`} onClick={handleShowImage(idx)} style={{ width: '100%', overflow: 'hidden', 'object-position': 'top' }} />
                                        {isNoticePopupIdx && isNoticePopupIdx.thumnail_idx === item.thumnail_idx && (
                                            <span
                                                className={'position-absolute top-0 left-0 z-1 m-0 text-warning'}
                                                style={{
                                                    padding: '1px',
                                                    margin: '0',
                                                    zIndex: '2',
                                                }}
                                            >
                                                <Stack spacing={1}>
                                                    <Chip label="팝업" color="primary" style={{ borderRadius: 0 }} />
                                                </Stack>
                                            </span>
                                        )}
                                    </ImageListItem>
                                </>
                            ))}
                        </ImageList>
                    </div>
                </div>
            </div>
            <Modal show={isShowImagePopup} onHide={handleCloseImage}>
                <Modal.Body className="p-0">
                    <div style={{ position: 'relative' }}>
                        <TransformWrapper initialScale={1} limitToBounds={true} alignmentAnimation={{ sizeX: 0, sizeY: 0 }} centerOnInit={false}>
                            {({ zoomIn, zoomOut, resetTransform, zoomToElement }) => (
                                <>
                                    <div style={{ padding: '10px' }}>
                                        <TransformComponent
                                            wrapperStyle={{
                                                height: '93dvh',
                                                width: '100%',
                                            }}
                                        >
                                            <div>
                                                <img src={isSelectImage} width="100%" />
                                            </div>
                                        </TransformComponent>
                                    </div>
                                </>
                            )}
                        </TransformWrapper>

                        <div style={{ position: 'absolute', top: '5px', left: '5px' }} className="fw-bold">
                            {isSelectImageIdx + 1} / {isImages.length}
                        </div>

                        <div style={{ position: 'absolute', bottom: '5px', right: '5px' }}>
                            <Button className="btn_close" variant="secondary" onClick={handleCloseImage}>
                                닫기
                            </Button>
                        </div>

                        {isSelectImageIdx === 0 ? (
                            ''
                        ) : (
                            <ArrowBackIosNewIcon
                                style={{ position: 'absolute', left: '-10px', top: '50%', 'margin-top': '-15px', cursor: 'pointer' }}
                                opacity="0.3"
                                sx={{
                                    fontSize: {
                                        xs: '40px',
                                        md: '50px',
                                        lg: '60px',
                                    },
                                }}
                                onClick={() => setMoveImage('left')}
                            />
                        )}

                        {isImages.length === isSelectImageIdx + 1 ? (
                            ''
                        ) : (
                            <ArrowForwardIosIcon
                                style={{ position: 'absolute', right: '-10px', top: '50%', 'margin-top': '-15px', cursor: 'pointer' }}
                                opacity="0.3"
                                sx={{
                                    fontSize: {
                                        xs: '40px',
                                        md: '50px',
                                        lg: '60px',
                                    },
                                }}
                                onClick={() => setMoveImage('right')}
                            />
                        )}
                    </div>
                </Modal.Body>
            </Modal>

            <Dropdown className="position-fixed end-0 m-2" drop={'up'} style={{ bottom: '240px', width: '49.79px', height: '49.79px' }}>
                <Dropdown.Toggle variant="light" id="dropdown-basic" className="rounded-circle p-2">
                    <SettingsIcon style={{ color: '#000' }} />
                </Dropdown.Toggle>
                <Dropdown.Menu style={{ height: '230px', width: '240px', overflowX: 'hidden', overflowY: 'auto', borderRadius: '10px' }}>
                    <div className="p-2 ps-3">
                        <div className="fw-bold h4">PROMISE 환경설정</div>
                        <FormControlLabel control={<IOSSwitch sx={{ m: 1 }} defaultChecked={isAgreementPwdChkYn} />} label="CODE 사용 여부" onClick={() => setAgreementPwdChkYn(!isAgreementPwdChkYn)} />
                        <TextField
                            id="input-with-icon-textfield"
                            label="CODE"
                            sx={{ m: 0 }}
                            slotProps={{
                                input: {
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <AccountCircle />
                                        </InputAdornment>
                                    ),
                                },
                            }}
                            focused
                            variant="standard"
                            onChange={(e) => {
                                setAgreementPwd(e.target.value);
                            }}
                            InputProps={{
                                inputProps: {
                                    maxLength: 10,
                                },
                            }}
                            value={isAgreementPwd}
                        />

                        <button type="button" className="mt-2 btn-sm btn-primary waves-effect waves-light float-end border-0 me-2" onClick={() => goAgreementSettingUpdate()}>
                            저장
                        </button>
                    </div>
                </Dropdown.Menu>
            </Dropdown>
            <Dropdown className="position-fixed end-0 m-2" drop={'up'} style={{ bottom: '180px', width: '49.79px', height: '49.79px' }}>
                <Dropdown.Toggle variant="secondary" id="dropdown-basic" className="rounded-circle p-2">
                    <SettingsIcon style={{ color: '#fff' }} />
                </Dropdown.Toggle>
                <Dropdown.Menu style={{ height: '300px', width: '240px', overflowX: 'hidden', overflowY: 'auto', borderRadius: '10px' }}>
                    <div className="p-2 ps-3">
                        <div className="fw-bold h4">LAB 환경설정</div>
                        <FormControlLabel control={<IOSSwitch sx={{ m: 1 }} defaultChecked={isPwdChkYn} />} label="CODE 사용 여부" onClick={() => setPwdChkYn(!isPwdChkYn)} />
                        <TextField
                            id="input-with-icon-textfield"
                            label="CODE"
                            sx={{ m: 0 }}
                            slotProps={{
                                input: {
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <AccountCircle />
                                        </InputAdornment>
                                    ),
                                },
                            }}
                            focused
                            variant="standard"
                            onChange={(e) => {
                                setPwd(e.target.value);
                            }}
                            InputProps={{
                                inputProps: {
                                    maxLength: 10,
                                },
                            }}
                            value={isPwd}
                        />
                        <div className="mt-3">
                            <FormControlLabel control={<IOSSwitch sx={{ m: 1 }} defaultChecked={isPopupYn} />} label="공지 사용 여부" onClick={() => setPopupYn(!isPopupYn)} />
                        </div>

                        <button type="button" className="mt-2 btn-sm btn-primary waves-effect waves-light float-end border-0 me-2" onClick={() => goSettingUpdate()}>
                            저장
                        </button>
                    </div>
                </Dropdown.Menu>
            </Dropdown>

            <Button className="position-fixed btn btn-success rounded-circle end-0 p-0 pt-2 pb-2 m-2" style={{ bottom: '120px', width: '49.79px', height: '49.79px' }} onClick={() => goNoticeUpdate()}>
                <CreateIcon style={{ color: '#fff' }} />
            </Button>
            <Button className="position-fixed btn btn-danger rounded-circle end-0 p-0 pt-2 pb-2 m-2" style={{ bottom: '60px', width: '49.79px', height: '49.79px' }} onClick={() => goNoticeUpdate('edit')}>
                <EditNoteIcon style={{ color: '#fff' }} />
            </Button>
            <Dropdown className="position-fixed right-0 bottom-0 end-0 m-2" drop={'up'}>
                <span className="me-2">{isCurrentDt}</span>

                <Dropdown.Toggle variant="primary" id="dropdown-basic" className="rounded-circle p-2">
                    <CalendarMonthIcon style={{ color: '#fff' }} />
                </Dropdown.Toggle>
                <Dropdown.Menu style={{ height: '200px', overflowX: 'hidden', overflowY: 'auto', borderRadius: '10px' }}>
                    {isDate.map((item) => (
                        <>
                            <Dropdown.Item className="h5" onClick={() => getNoticeList(item.idx)}>
                                <div className="d-flex">
                                    <div style={{ width: '27px' }} onClick={() => fileDownload(`${isSelectImage}`, `${isAttachFile.origin_name}`)}>
                                        {item.idx == isCurrentIdx ? <CheckIcon className="text-blue fw-bold" style={{ marginTop: '-4px' }} /> : ''}
                                    </div>
                                    <div>{item.dt}</div>
                                </div>
                            </Dropdown.Item>
                            <Dropdown.Divider />
                        </>
                    ))}
                    <i className="bi bi-check"></i>
                </Dropdown.Menu>
            </Dropdown>
            {isLoading ? <Loading /> : null}
            <ToastContainer
                position="top-right" // 알람 위치 지정
                autoClose={3000} // 자동 off 시간
                hideProgressBar={false} // 진행시간바 숨김
                closeOnClick // 클릭으로 알람 닫기
                rtl={false} // 알림 좌우 반전
                pauseOnFocusLoss // 화면을 벗어나면 알람 정지
                draggable // 드래그 가능
                pauseOnHover // 마우스를 올리면 알람 정지
                theme="light"
                // limit={1} // 알람 개수 제한
            />
        </>
    );
};

export { OrderAdminNoticePage };
