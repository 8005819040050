// Loading.js
import React, { useEffect, useState, useRef } from 'react';
import Button from 'react-bootstrap/Button';
import InputMask from 'react-input-mask';
import { useNavigate } from 'react-router-dom';
import rainbow_logo from '../../assets/images/rainbow-logo3.png';
import './intro.css';

import apiClient from '../../apiClient';

const AgreementSmsPage = () => {
    const MINUTES_IN_MS = 3 * 60 * 1000;
    const INTERVAL = 1000;
    const [isTimeLeft, setTimeLeft] = useState(0);
    const minutes = String(Math.floor((isTimeLeft / (1000 * 60)) % 60)).padStart(2, '0');
    const second = String(Math.floor((isTimeLeft / 1000) % 60)).padStart(2, '0');

    const navigate = useNavigate();
    const [isPhone, setPhone] = useState('');
    const [isSmsSend, setSmsSend] = useState(false);
    const [isSmsEnd, setSmsEnd] = useState(false);
    const [isVerify, setVerify] = useState('');

    const nextPage = () => {
        navigate(`/agreement/sign`);
    };

    const smsSend = (e) => {
        setSmsEnd(false);
        setVerify('');
        var regExp = /^01(?:0|1|[6-9])-(?:\d{3}|\d{4})-\d{4}$/;

        if (isPhone === '') {
            alert('휴대폰 번호를 입력하세요.');
            return;
        }

        if (!regExp.test(isPhone)) {
            alert('휴대폰번호가 올바르지 않습니다.');
            return;
        }
        const payload = { phone: isPhone };
        apiClient
            .post('/agreement/sms', payload)
            .then((res) => {
                if (res.data.result_code === '1' && res.data.message == 'success') {
                    setSmsSend(true);
                    setTimeLeft(MINUTES_IN_MS);
                } else {
                    setSmsSend(false);
                    alert('처리도중 오류가 발생했습니다. 전화번호를 확인하세요.');
                }
            })
            .catch((err) => {
                alert('처리도중 오류가 발생했습니다. 지속적으로 발생시 관리자에게 문의하세요.');
                cancel();
            });
    };

    const smsVerify = async (e) => {
        if (isVerify === '') {
            alert('인증번호를 입력하세요.');
            return;
        }
        const payload = { verify: isVerify, phone: isPhone };
        apiClient
            .post('/agreement/verify', payload)
            .then((res) => {
                if (res.data.status == 'fail') {
                    if (res.data.message == 'error') {
                        alert('처리도중 오류가 발생했습니다. 전화번호를 확인하세요.');
                        cancel();
                    }

                    if (res.data.message == 'verify') {
                        alert('인증번호가 일치하지 않습니다.');
                        setVerify('');
                    }
                } else {
                    localStorage.setItem('phone', isPhone);
                    nextPage();
                }
            })
            .catch((err) => {
                cancel();
            });
    };

    const activeEnter = (e) => {
        if (e.key === 'Enter') {
            smsSend();
        }
    };

    const activeVeryEnter = (e) => {
        if (e.key === 'Enter') {
            smsVerify();
        }
    };

    const cancel = () => {
        setPhone('');
        setSmsSend(false);
        setSmsEnd(false);
    };

    useEffect(() => {
        const timer = setInterval(() => {
            setTimeLeft((prevTime) => prevTime - INTERVAL);
        }, INTERVAL);

        if (isTimeLeft <= 0) {
            clearInterval(timer);
            if (isSmsSend) {
                setSmsEnd(true);
            }
        }

        return () => {
            clearInterval(timer);
        };
    }, [isTimeLeft]);

    useEffect(() => {
        const verify = localStorage.getItem(`accessTokenAgreement`);
        if (verify === null) {
            alert('세션이 종료 되어 처음 화면으로 이동합니다.');
            window.location.href = '/agreement/';
            return;
        }

        const payload = { token: verify };
        apiClient
            .post('/agreement/verifyPass', payload)
            .then((res) => {
                if (res.data.result == false) {
                    if (res.data.msg == 'token error') {
                        alert('세션이 종료 되어 처음 화면으로 이동합니다.');
                        window.location.href = '/agreement/';
                    }
                }
            })
            .catch((err) => {
                alert('시스템 오류가 발생했습니다. 관리자에게 문의하세요.');
                window.location.href = '/agreement/';
            });
    }, []);

    return (
        <div className="page-content">
            <div className="container">
                <div className="row p-3 pt-5">
                    <div className="col-12 col-lg-6 col-xl-5 col-xxl-5 mx-auto">
                        <div className="card rounded-1" style={{ backgroundColor: 'rgba(255, 255, 255, .1)' }}>
                            <div className="card-body text-center p-4 pt-5 pb-5">
                                <img src={rainbow_logo} width="130px" alt="" />
                                <h4 className="mb-0 mt-3 fw-bold text-center text-white">근로계약서 전자서명</h4>
                                <hr />
                                <div className="col-11 mx-auto">
                                    {isSmsSend === false ? (
                                        <InputMask
                                            type="tel"
                                            mask={'999-9999-9999'}
                                            maskPlaceholder={''}
                                            // maxLength={13}
                                            placeholder="휴대폰 번호를 입력하세요."
                                            autoFocus
                                            onChange={(e) => {
                                                setPhone(e.target.value);
                                            }}
                                            onKeyDown={(e) => activeEnter(e)}
                                            autocomplete="off"
                                            className="form-control text-center"
                                            value={isPhone}
                                        />
                                    ) : (
                                        <div className="text-white text-start py-2 px-1">인증번호를 {isPhone}으로 전송했습니다.</div>
                                    )}
                                    {isSmsSend && (
                                        <>
                                            <InputMask
                                                type="tel"
                                                mask={'999999'}
                                                maskPlaceholder={''}
                                                // maxLength={13}
                                                placeholder="인증번호를 입력하세요."
                                                autoFocus
                                                autocomplete="off"
                                                className="form-control text-center"
                                                disabled={isSmsEnd ? true : false}
                                                style={{ backgroundColor: isSmsEnd ? 'darkgray' : '' }}
                                                onChange={(e) => {
                                                    setVerify(e.target.value);
                                                }}
                                                onKeyDown={(e) => activeVeryEnter(e)}
                                                value={isVerify}
                                            />
                                            <div className="d-flex justify-content-between text-white p-1 pt-2">
                                                {isSmsEnd ? (
                                                    <span className="text-danger">입력 시간이 초과 되었습니다.</span>
                                                ) : (
                                                    <>
                                                        <span></span>
                                                        <span>
                                                            {minutes}:{second}
                                                        </span>
                                                    </>
                                                )}
                                            </div>
                                        </>
                                    )}
                                    {isSmsEnd ? (
                                        <>
                                            <Button
                                                variant="primary"
                                                className="mt-3 p-2 px-3 fw-bold"
                                                onClick={() => {
                                                    smsSend();
                                                }}
                                            >
                                                <div>재전송</div>
                                            </Button>

                                            <Button
                                                variant="warning"
                                                className="mt-3 p-2 px-3 fw-bold ms-2"
                                                onClick={() => {
                                                    cancel();
                                                }}
                                            >
                                                <div>취소</div>
                                            </Button>
                                        </>
                                    ) : isSmsSend ? (
                                        <Button
                                            variant="warning"
                                            className="mt-3 p-2 px-3 fw-bold ms-2"
                                            onClick={() => {
                                                smsVerify();
                                            }}
                                        >
                                            <div>인증번호 확인</div>
                                        </Button>
                                    ) : (
                                        <Button
                                            variant="warning"
                                            className="mt-3 p-2 px-3 fw-bold ms-2"
                                            onClick={() => {
                                                smsSend();
                                            }}
                                        >
                                            <div>인증</div>
                                        </Button>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export { AgreementSmsPage };
